
import Vue from "vue";
import Header from "@/components/Header.vue";
import chatService from "@/services/chat.service";
import HomeService from "@/services/home.service";
export default Vue.extend({
  name: "Short",
  components: {
    'Header': Header,
  },
  data() {
    return {
      modal:true,
      claroOscuro:String(localStorage.getItem('claroOscuro')),
      originBot:String(localStorage.getItem('originBot')),
      urlRedirigir:"",
      dataurl:"",
      requestDerivacion:{
        id:"",
        caseOfUse:"",
        appType:"",
      }
    };
  },
  created() {
    this.cargaEstilos();
  },
  mounted() {
    console.log("Derivacion");
    var url:string = window.location+"";
    
    if(url.includes("ChatWab")){
      this.requestDerivacion.appType="WhatsApp"
      this.requestDerivacion.caseOfUse = this.$route.query.cu+"";
    } else if (url.includes("ChatFB")){
      this.requestDerivacion.appType="Facebook"
      this.requestDerivacion.caseOfUse = this.$route.query.cu+"";
    } else if (url.includes("ChatAbc")){
      this.requestDerivacion.appType="AppleMessagesforBusiness"
      this.requestDerivacion.caseOfUse = this.$route.query.cu+"";
    } else if (url.includes("Apple2Web")){
      console.log("Derivación Apple2Web");
      this.requestDerivacion.appType="Apple2Web"
    } else {
      this.requestDerivacion.appType="Derivacion";
      this.requestDerivacion.id = this.$route.params.id!=undefined ? this.$route.params.id:"";
    }
    //setTimeout(()=>{
    this.cargaInicial();
    //},5000);
  },
  methods: {
    cargaInicial: function () {
      chatService.derivacion(this.requestDerivacion).then((resp) => {
        console.log(resp.data.code);
        if(resp.data.code==200){
          this.dataurl = resp.data.UrlPortalWeb + "/Chat/Chat?id=" + resp.data.clientEncode;
          var url=window.location.host+"";
  

          if(url.includes("localhost")){
            console.log("78"+  "/Chat/Chat?id=" + resp.data.clientEncode );
            this.$router.push("/Chat/Chat?id=" + resp.data.clientEncode);

          }else{
            console.log("81"+  this.dataurl );
            location.href = this.dataurl;
       
          }
        }else{
          console.log("ErrorDerivacionDir"+  resp.data.pathError );       
          this.$router.push(resp.data.pathError);
        }
      }).catch(error=>{
        console.log(error);
        this.$router.push("/Error");
      });
    },
    cargaEstilos(){
      HomeService.CargaInicial().then((resp) => {
        this.claroOscuro = resp.data.claroOscuro;
        this.originBot = resp.data.originBot;
        localStorage.setItem("claroOscuro", resp.data.claroOscuro);
        localStorage.setItem("originBot", resp.data.originBot);
        localStorage.setItem("pais", resp.data.pais);
        localStorage.setItem("urlRedirigir", resp.data.urlRedirigir);
        this.urlRedirigir = resp.data.urlRedirigir;
      }).catch((error) =>{
        console.error("Error al realizar la carga inicial Derivación ",error);
      });
    },
  },
});
